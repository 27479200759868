<template>
  <div class="account-settings-container">
    <div>
      <h2 class="account-settings-title">
        {{ $t("account_settings_title") | capitalize }}
      </h2>
      <p class="account-settings-description">
        {{ $t("account-settings_description") }}
      </p>
    </div>
    <div class="account-settings-buttonsgroup">
      <b-button class="account-settings-button">
        <router-link to="/subscription?webview=true">
          <div class="account-settings-button-content">
            <div class="account-settings-button-text">
              {{ $t("manage_your_subscription") | capitalize }}
              <div class="account-settings-button-subtext">
                {{ $t("view_and_renew_your_subscription") | capitalize }}
              </div>
            </div>
            <chevron :iconSize="14" />
          </div>
        </router-link>
      </b-button>
      <b-button class="account-settings-button">
        <router-link to="/account_profil_update">
          <div class="account-settings-button-content">
            <div class="account-settings-button-text">
              {{ $t("profile") | capitalize }}
              <div class="account-settings-button-subtext">
                {{ getUserSetting ? getUserSetting.firstname : '' }}
                {{ getUserSetting ? getUserSetting.lastname : '' }}
                <span v-if="getUserSetting && getUserSetting.birthday">
                  <!-- Do no show birthday yet, nor here anyway. It is not important. -->
                  <!-- - 
                  {{ getUserSetting.birthday | formatShortDay }} -->
                </span>
              </div>
            </div>
            <chevron :iconSize="14" />
          </div>
        </router-link>
      </b-button>
      <b-button v-if="this.isV2" class="account-settings-button">
        <router-link to="/account_email_update">
          <div class="account-settings-button-content">
            <div class="account-settings-button-text">
              {{ $t("manage_your_mail") | capitalize }}
              <div class="account-settings-button-subtext">
                {{ getUserName }}
              </div>
            </div>
            <chevron :iconSize="14" />
          </div>
        </router-link>
      </b-button>
      <b-button v-if="isAndroid"  @click="logout()" class="account-settings-button">
          <div class="account-settings-button-content">
            <div class="account-settings-button-text">
              {{ $t("logout") | capitalize }}
            </div>
            <chevron :iconSize="14" />
          </div>
      </b-button>
      <b-button class="account-settings-button">
        <router-link to="/account_delete">
          <div class="account-settings-button-content account-alert-text">
            {{ $t("delete_your_account") | capitalize }}
            <chevron :iconSize="14" :iconColor="'red'" />
          </div>
        </router-link>
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Chevron from "../svg/chevron.vue";

export default {
  components: { Chevron },
  name: "AccountSettings",

  data() {
    return {
      
    };
  },

  computed: {
    ...mapGetters("users", ["getUserName", "getUserSetting"]),
    ...mapGetters("appcompat", ["isVerifyNative"]),
    isAndroid() {
      return /Android/.test(navigator.userAgent);
    },
    isV2() {
      return this.isVerifyNative
    }
  },

  methods: {
    ...mapActions("users", ["setToken", "fetchUserData", "fetchUserSetting"]),
    ...mapActions("appcompat", ["setIsVerifyNative"]),

    setV2(value) {
      this.setIsVerifyNative(value)
    },
    logout() {
      window.location.href = '/logout/';
    },


  },

  async mounted() {
    await this.fetchUserData();
    await this.fetchUserSetting();
    window.zE("webWidget", "hide");
  },
  
  async created() {
    let parameters = this.$route.query;
    if (parameters["token"]) {
      let token = parameters["token"];
      this.setToken(token);
    }
    let routeParams = this.$route.params
    if (routeParams["pageVersion"]) {
      let pageVersion = routeParams["pageVersion"]
      this.setV2( pageVersion != "v1")
    }
    
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.account-settings-container {
  min-height: 100vh;
  margin-bottom: 9em;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.5em;

  .account-settings-title {
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    font-size: 28px;
    text-align: left;
  }

  .account-alert-text {
    text-align: left;
    color: #f95658 !important;
    font-weight: 500 !important;
  }

  .account-settings {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    &-description {
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      font-size: 14px;
      color: #555b62;
      margin: 0;
    }

    &-buttonsgroup {
      display: flex;
      flex-direction: column;
      gap: 0.5em;

      .button-expand {
        padding: 0em 1em !important;

        &-header {
          padding: 1em 0em;
        }
      }

      .account-settings-button {
        background: #ffffff;
        border: 1px solid #eaeaea;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 1em;
        box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.05);

        a:hover {
          text-decoration: none !important;
        }

        &:focus {
          box-shadow: none;
        }

        &-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #050328;
          font-family: "Open Sans", sans-serif;
          font-weight: 600;
          font-size: 14px;
          text-align: left;
        }

        &-subtext {
          color: #8b949f;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
        }

        &:last-child {
          margin-top: 2em;
        }
      }

      .btn-secondary:not(:disabled):not(.disabled).is-invalid {
        border: 1px solid #f95658 !important;
        background: #feeeee;
        box-shadow: 0px 0px 0px 1px #feeeee !important;
      }

      .btn-secondary:not(:disabled):not(.disabled).active {
        border: 1px solid #0090f8 !important;
        box-shadow: 0px 0px 0px 1px #0090f8 !important;
      }

      .account-expand-summary {
        display: flex;
        flex-direction: column;
        gap: 0.5em;

        .expand-summary-item {
          font-family: "Open Sans";
          font-weight: 400;
          font-size: 14px;
          color: #555b62;
          text-align: left;

          &:last-child {
            padding-bottom: 1em;
          }
        }

        .expand-summary-item.is-invalid {
          color: #f95658 !important;
          font-weight: 500 !important;
          border: none !important;
          background: transparent !important;
        }
      }

      .button-expand-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .button-expand {
        &-title {
          color: #050328;
          font-family: "Open Sans", sans-serif;
          font-weight: 600;
          font-size: 14px;
          text-align: left;
        }

        &-body {
          display: flex;
          flex-direction: column;
          gap: 0.5em;
          border-top: #eaeaea 2px solid;
          padding: 1em 0em;
        }

        &-inputgroup {
          display: flex;
          flex-direction: column;
        }

        &-label {
          font-family: "Open Sans";
          font-weight: 400;
          font-size: 14px;
          color: #555b62;
          text-align: left;
        }

        &-input {
          background: #ffffff;
          border: 1px solid #8b949f;
          box-sizing: border-box;
          border-radius: 8px;
          font-family: "Open Sans";
          font-size: 14px;
          padding: 0.75em;
          margin-top: 0.5em;

          &:focus-visible {
            border: 1px solid #0090f8 !important;
            box-shadow: 0px 0px 0px 1px #0090f8 !important;
            outline: none;
            background: white;
          }
        }

        .is-invalid {
          border: 1px solid #f95658 !important;
          background: #feeeee;

          &:focus-visible {
            border: 1px solid #f95658 !important;
            box-shadow: 0px 0px 0px 1px #feeeee !important;
          }
        }
      }
    }
  }
}
</style>